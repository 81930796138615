@import "1-variables/app";

#root {
  position: relative;
}

a.active {
  color: $primary!important;
}

[data-slate-editor=true] {
  figure {
    width: 100%;
    text-align: center;
    img {
      width: 100%;
    }
    img.slate-img-lg {
      max-width: 48rem;
    }
    img.slate-img-sm {
      max-width: 15rem;
    }
    figcaption {
      color: $gray-500;
    }
  }
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

.btn {
  position: relative!important;

  .spinner-border {
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: 50%;
    top: 50%;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
  }

  .spinner-border-sm {
    position: absolute;
    width: 0.875rem;
    height: 0.875rem;
    left: 50%;
    top: 50%;
    margin-left: -0.4375rem;
    margin-top: -0.4375rem;
  }
}

.react-dropdown-tree-select {

  // overflow: hidden;

  // text-align: center;

  .tag-list {
    display: none;
  }

  .dropdown {

    .dropdown-trigger.arrow.top:after, .dropdown-trigger.arrow.bottom:after {
      box-sizing: border-box;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      font-size: 1rem;
      font-style: normal;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      vertical-align: middle;
      content: "\f002";
    }
  }
}

.toggle.collapsed:after {
  box-sizing: border-box;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  font-style: normal;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  // vertical-align: middle;
  content: "\f0da";
}

.toggle.expanded:after {
  box-sizing: border-box;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  font-style: normal;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  // vertical-align: middle;
  content: "\f0d7";
}